import React from "react";
import Showcase from "../components/sections/Showcase";
import About from "../components/sections/about/About";
import Intro from "../components/sections/intro/Intro";
import Testimonial from "../components/sections/Testimonial";
import Layout from "../components/layout";
import Catering from "../components/sections/Catering";
import BestSeller from "../components/sections/BestSeller";
import { graphql } from "gatsby";

const IndexPage = ({ data }) => {
  return (
    <Layout
      title={data.strapiSeo.PageTitle}
      description={data.strapiSeo.PageDescription}
    >
      <Showcase />
      <About />
      <Intro />
      <Catering />
      <BestSeller />
      <Testimonial />
    </Layout>
  );
};
export const query = graphql`
  query {
    strapiSeo(NameOfPage: { eq: "home" }) {
      PageDescription
      PageTitle
    }
  }
`;
export default IndexPage;
