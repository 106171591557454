// extracted by mini-css-extract-plugin
export const cauldron = "cauldron-module--cauldron--2B1I5";
export const fire = "cauldron-module--fire--3FmQI";
export const burn = "cauldron-module--burn--29XWK";
export const stick = "cauldron-module--stick--19Phn";
export const move = "cauldron-module--move--t1_T3";
export const smoke1 = "cauldron-module--smoke1--2NpeL";
export const rise1 = "cauldron-module--rise1--tfBTN";
export const smoke2 = "cauldron-module--smoke2--1ibn5";
export const rise2 = "cauldron-module--rise2--10JrP";
export const smoke3 = "cauldron-module--smoke3--2J_Sr";
export const rise3 = "cauldron-module--rise3--eYTnU";