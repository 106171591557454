import React from "react";
import * as styles from "../../../styles/sections/intro/intro.module.css";
import { useStaticQuery, graphql } from "gatsby";
import Cauldron from "./Cauldron";
import Section from "../Section";
import Text from "../../SVG/TextDivider";

function Intro() {
  const data = useStaticQuery(graphql`
    {
      strapiHomepage {
        IntroInfo
        IntroTitle
      }
    }
  `);
  return (
    <Section>
      <div className={styles.container}>
        <div className={styles.innerBorder}>
          <div className={styles.bg}>
            <Cauldron />
          </div>

          <div className={styles.info}>
            <Text
              style={{ color: "#fbb755",fontSize: "4rem" }}
              fill="#fbb755"
            >
              {data.strapiHomepage.IntroTitle}
            </Text>
            <p>{data.strapiHomepage.IntroInfo}</p>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default Intro;
