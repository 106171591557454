// extracted by mini-css-extract-plugin
export const container = "bestseller-module--container--3pE5H";
export const wave1 = "bestseller-module--wave1--3upDW";
export const wave2 = "bestseller-module--wave2--3R_An";
export const wave = "bestseller-module--wave--2uxr_";
export const flow = "bestseller-module--flow--35Zdo";
export const flow1 = "bestseller-module--flow1--1_oha";
export const arrowContainer = "bestseller-module--arrowContainer--3NCPs";
export const leftArrow = "bestseller-module--leftArrow--2UIa4";
export const rightArrow = "bestseller-module--rightArrow--3vuUe";
export const arrow = "bestseller-module--arrow--3PCHF";
export const CardCollection = "bestseller-module--CardCollection--1uKcT";
export const card = "bestseller-module--card--16K__";
export const cardImg = "bestseller-module--cardImg--3VnJh";
export const cardInfo = "bestseller-module--cardInfo--2yUXk";
export const fillChange = "bestseller-module--fillChange--HBL3e";
export const flow2 = "bestseller-module--flow2--FL55c";