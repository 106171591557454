import React from "react";
import * as styles from "../../styles/sections/section.module.css";

function Section({ children, style, id }) {
  return (
    <div
      id={id}
      className={styles.container}
      style={{
        ...style,
      }}
    >
      {children}
    </div>
  );
}

export default Section;
