import React from "react";
import * as styles from "../../../styles/sections/intro/cauldron.module.css";

function Cauldron1() {
  return (
    <div>
      <svg
        className={styles.cauldron}
        //   width="100%"
        // width="512"
        // height="537"
        viewBox="0 0 512 537"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="pot 1">
          <g id="background">
            <path
              id="Vector"
              d="M512 281C512 422.385 397.385 537 256 537C114.615 537 0 422.385 0 281C0 139.615 114.615 25 256 25C397.385 25 512 139.615 512 281Z"
              fill="#614122bf"
            />
          </g>
          <g className={styles.stick} id="Stick">
            <path
              id="Vector_2"
              d="M203.848 269.142C196.037 276.953 183.374 276.953 175.564 269.142L5.85799 99.436C-1.95201 91.626 -1.95201 78.962 5.85799 71.152C13.668 63.342 26.332 63.342 34.142 71.152L203.848 240.858C211.658 248.668 211.658 261.332 203.848 269.142Z"
              fill="#232223"
            />
            <path
              id="Vector_3"
              d="M203.848 240.858C209.868 246.878 211.228 255.773 207.968 263.115C200.626 266.375 191.731 265.015 185.711 258.995L16.005 89.289C9.98501 83.269 8.62501 74.373 11.885 67.032C19.227 63.772 28.122 65.132 34.142 71.152L203.848 240.858Z"
              fill="#3e3b3b"
            />
          </g>
          <g id="Body">
            <path
              id="Vector_4"
              d="M84.423 470.986C56.722 438.373 40 396.142 40 350C40 322.246 48.38 296.452 62.74 275H55C46.716 275 40 268.284 40 260V230C40 221.716 46.716 215 55 215H175H337H457C465.284 215 472 221.716 472 230V260C472 268.284 465.284 275 457 275H449.26C463.62 296.452 472 322.246 472 350C472 396.142 455.278 438.372 427.577 470.985C427.082 471.433 426.575 471.868 426.076 472.311H85.924C85.425 471.868 84.919 471.433 84.423 470.986Z"
              fill="#14486f"
            />
            <path
              id="Vector_5"
              d="M84.423 470.986C56.722 438.373 40 396.142 40 350C40 322.243 48.389 296.453 62.754 275H55C46.716 275 40 268.284 40 260V230C40 221.716 46.716 215 55 215H83C74.716 215 68 221.716 68 230V260C68 268.284 74.716 275 83 275H432V280C432 288.284 425.284 295 417 295H120.451C108.23 295 98.915 305.858 100.663 317.953C109.497 379.052 139.915 433.158 183.93 472.312H85.924C85.425 471.868 84.919 471.433 84.423 470.986Z"
              fill="#1b252d"
            />
          </g>
          <g className={styles.fire} id="Fire">
            <path
              id="Vector_6"
              d="M85.802 472.208C85.802 472.208 112.5 468.5 138.99 419.02C145.08 407.645 175.485 455.515 175.485 455.515C175.485 455.515 239.5 414 256 355C264.671 323.993 336.515 455.515 336.515 455.515C336.515 455.515 365.407 408.595 373.01 419.02C408 467 426.198 472.208 426.198 472.208C380.962 512.503 321.346 537 256 537C190.654 537 131.038 512.503 85.802 472.208Z"
              fill="#b3411b"
            />
            <path
              id="Vector_7"
              d="M112.787 493.223C112.787 493.223 129.778 466.042 138.99 467.02C151.821 468.382 162.582 503.515 175.485 503.515C203.951 503.515 262.7 387.485 270 415C283 464 308.049 503.515 336.515 503.515C349.418 503.515 360.179 468.382 373.01 467.02C382.222 466.042 399.213 493.223 399.213 493.223C358.339 520.86 309.055 537 256 537C202.945 537 153.661 520.86 112.787 493.223Z"
              fill="#c39a40"
            />
          </g>
          <g className={styles.smoke2} id="smoke2">
            <g id="Group">
              <path
                id="Vector_8"
                d="M362.278 108.885C363.022 108.885 363.776 109.103 364.435 109.551C366.183 110.747 366.631 113.13 365.435 114.877C365.102 115.372 357.209 127.399 365.397 138.948C374.52 151.819 369.332 165.157 365.317 170.414C364.037 172.092 361.631 172.422 359.945 171.13C358.266 169.847 357.945 167.452 359.22 165.77C359.604 165.256 367.21 154.754 359.144 143.381C347.829 127.422 358.642 111.233 359.106 110.551C359.853 109.467 361.056 108.885 362.278 108.885V108.885Z"
                fill="#FFFFFF"
              />
            </g>
            <g id="Group_2">
              <path
                id="Vector_9"
                d="M439.364 61C440.107 61 440.862 61.2184 441.521 61.6667C443.268 62.8622 443.717 65.2455 442.521 66.9927C442.188 67.487 434.295 79.5147 442.483 91.0672C443.705 92.7915 443.299 95.1863 441.571 96.4086C439.843 97.6347 437.456 97.2247 436.23 95.4966C424.915 79.5338 435.728 63.345 436.191 62.6668C436.942 61.5862 438.142 61 439.364 61V61Z"
                fill="#FFFFFF"
              />
            </g>
          </g>
          <g className={styles.smoke3} id="smoke3">
            <g id="Group_3">
              <path
                id="Vector_10"
                d="M249.754 151.286C250.271 151.286 250.796 151.438 251.254 151.75C252.469 152.581 252.78 154.238 251.949 155.452C251.717 155.796 246.231 164.157 251.923 172.185C258.264 181.131 254.658 190.403 251.867 194.058C250.977 195.224 249.304 195.453 248.132 194.556C246.966 193.663 246.742 191.999 247.629 190.829C247.895 190.473 253.182 183.172 247.576 175.266C239.71 164.173 247.227 152.919 247.549 152.445C248.068 151.691 248.905 151.286 249.754 151.286V151.286Z"
                fill="#FFFFFF"
              />
            </g>
            <g id="Group_4">
              <path
                id="Vector_11"
                d="M303.34 118C303.856 118 304.381 118.152 304.839 118.463C306.054 119.294 306.365 120.951 305.534 122.166C305.303 122.509 299.816 130.87 305.508 138.901C306.358 140.099 306.075 141.764 304.874 142.614C303.673 143.466 302.013 143.181 301.161 141.98C293.295 130.884 300.812 119.63 301.134 119.159C301.656 118.408 302.49 118 303.34 118V118Z"
                fill="#FFFFFF"
              />
            </g>
          </g>
          <g className={styles.smoke1} id="smoke1">
            <g id="Group_5">
              <path
                id="Vector_12"
                d="M249.527 89.2225C248.475 89.2225 247.406 88.9134 246.473 88.2788C244 86.5867 243.366 83.2133 245.058 80.7401C245.53 80.0405 256.702 63.0161 245.112 46.6697C232.199 28.4522 239.542 9.57298 245.226 2.13194C247.037 -0.243555 250.443 -0.709976 252.83 1.11774C255.205 2.93462 255.661 6.3243 253.855 8.70522C253.312 9.43197 242.547 24.2978 253.963 40.3947C269.979 62.9836 254.674 85.8979 254.017 86.8633C252.96 88.3981 251.257 89.2225 249.527 89.2225V89.2225Z"
                fill="#FFFFFF"
              />
            </g>
            <g id="Group_6">
              <path
                id="Vector_13"
                d="M140.417 157C139.365 157 138.296 156.691 137.363 156.056C134.89 154.364 134.256 150.991 135.948 148.518C136.42 147.818 147.592 130.794 136.002 114.442C134.272 112.001 134.847 108.612 137.293 106.881C139.739 105.146 143.118 105.726 144.853 108.172C160.869 130.767 145.564 153.681 144.908 154.641C143.845 156.17 142.147 157 140.417 157V157Z"
                fill="#FFFFFF"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

export default Cauldron1;
