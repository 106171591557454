import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useEffect, useRef, useState } from "react";
import Section from "./Section";
import Text from "../SVG/TextDivider";
import * as styles from "../../styles/sections/bestseller.module.css";

const Wave = () => {
  return (
    <div>
      <svg
        className={styles.wave}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="#f9090a"
          fillOpacity="1"
          d="M0,64L60,53.3C120,43,240,21,360,32C480,43,600,85,720,96C840,107,960,85,1080,85.3C1200,85,1320,107,1380,117.3L1440,128L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
        ></path>
      </svg>

      <svg
        className={styles.wave1}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="#f9090a"
          fillOpacity="1"
          d="M0,128L40,149.3C80,171,160,213,240,213.3C320,213,400,171,480,133.3C560,96,640,64,720,85.3C800,107,880,181,960,213.3C1040,245,1120,235,1200,213.3C1280,192,1360,160,1400,144L1440,128L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
        ></path>
      </svg>

      <svg
        className={styles.wave2}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="#f9090a"
          fillOpacity="1"
          d="M0,224L40,192C80,160,160,96,240,64C320,32,400,32,480,69.3C560,107,640,181,720,197.3C800,213,880,171,960,138.7C1040,107,1120,85,1200,90.7C1280,96,1360,128,1400,144L1440,160L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
        ></path>
      </svg>
    </div>
  );
};

const Card = ({ product }) => {
  return (
    <Link to={`${product.slug}`}>
      <div className={styles.card}>
        <GatsbyImage
          className={styles.cardImg}
          image={getImage(product.img)}
          alt=""
        />

        <div className={styles.cardInfo}>
          <h5>{product.name}</h5>
          <p>{product.description}</p>
          <div className="primary-btn">Order Now</div>
        </div>
      </div>
    </Link>
  );
};

function BestSeller() {
  const cardCollectionRef = useRef();
  const scrollContainerRef = useRef();

  const data = useStaticQuery(graphql`
    {
      strapiBestSeller {
        products {
          img {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 350)
            }
          }
          name
          slug
          description
        }
      }
      strapiHomepage {
        BestSellerInfo
        BestSellerTitle
      }
    }
  `);
  //An empty array with elements equal to the number of cards to replicate
  const [replicateNumber, setReplicateNumber] = useState([]);
  //Check whether the scroll container is being hovered or not
  const [hover, setHover] = useState(false);
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    //Get the cards of the cardContainer
    const children = cardCollectionRef.current.childNodes;
    //Get the width of one card
    var cardWidth = children[0].getBoundingClientRect().width;
    //The numberOfProducts = number of cards
    var numberOfProducts = data.strapiBestSeller.products.length;
    //Number of cards to create a copy of
    var cardsToReplicate = 0;
    //Only if the cards overFlow the innerWidth do we need to replicate them
    if (cardWidth * numberOfProducts > window.innerWidth) {
      //The cards should overflow the screen
      while (cardsToReplicate * cardWidth < window.innerWidth) {
        cardsToReplicate += 1;
      }
    }

    //Update the state to create the duplicate cards
    setReplicateNumber([...Array(cardsToReplicate)]);

    //Either iterate over all the cards and get each width (If cards of different widths are there)
    // var scrollWidth = 0;
    // children.forEach((child) => {
    //   scrollWidth += child.getBoundingClientRect().width;
    // });

    //Use this to get amount of scroll req. since all cards are of same width
    var scrollWidth = cardWidth * numberOfProducts;

    //Scroll handling callback
    const handleScroll = (e) => {
      //Set to max scroll pos if overflow left
      if (e.target.scrollLeft === 0) {
        scrollContainer.scrollTo({
          top: 0,
          left: scrollWidth,
        });
      }
      //Set to min scroll pos if overflow right
      if (e.target.scrollLeft > scrollWidth) {
        scrollContainer.scrollTo({
          top: 0,
          left: 1,
        });
      }
    };

    const handleEnter = () => {
      //Mouse Enter
      setHover(true);
    };
    const handleLeave = () => {
      //Mouse Leave
      setHover(false);
    };
    //Timer for scrolling the content
    const timer = setInterval(() => {
      !hover && scrollContainer.scrollBy(1, 0); //To keep scrolling every 10milliseconds (only if mouse is not hovering over it)
    }, 10);
    scrollContainer.addEventListener("scroll", handleScroll); //To keep checking the scroll
    scrollContainer.addEventListener("mouseenter", handleEnter); //When mouse enters pause scroll
    scrollContainer.addEventListener("touchstart", handleEnter, {
      passive: true,
    }); //When mouse enters pause scroll
    scrollContainer.addEventListener("mouseleave", handleLeave); //When mouse leaves resume scroll
    scrollContainer.addEventListener("touchend", handleLeave, {
      passive: true,
    }); //When mouse leaves resume scroll

    return () => {
      scrollContainer.addEventListener("scroll", handleScroll); //To keep checking the scroll
      scrollContainer.addEventListener("mouseenter", handleEnter); //When mouse enters pause scroll
      scrollContainer.addEventListener("touchstart", handleEnter, {
        passive: true,
      }); //When mouse enters pause scroll
      scrollContainer.addEventListener("mouseleave", handleLeave); //When mouse leaves resume scroll
      scrollContainer.addEventListener("touchend", handleLeave, {
        passive: true,
      }); //When mouse leaves resume scroll
      clearInterval(timer);
    };
  }, [hover]);
  return (
    <Section id="BestSeller" style={{ marginBottom: 0 }}>
      <Text containerStyle={{ zIndex: `4`, position: "relative" }}>
        {data.strapiHomepage.BestSellerTitle}
      </Text>
      <div className={styles.container}>
        <h3
          style={{
            textAlign: "center",
            marginBottom: "2rem",
            marginTop: "1rem",
          }}
        >
          {data.strapiHomepage.BestSellerInfo}
        </h3>
        <div
          id="bestSellerScrollContainer"
          ref={scrollContainerRef}
          style={{ width: `100vw`, overflow: `scroll` }}
        >
          <div ref={cardCollectionRef} className={styles.CardCollection}>
            {data.strapiBestSeller.products.map((product, index) => {
              return (
                <span key={index}>
                  <Card product={product} />
                </span>
              );
            })}
            {replicateNumber.map((NA, index) => {
              return (
                <span key={index}>
                  <Card product={data.strapiBestSeller.products[index]} />
                </span>
              );
            })}
          </div>
        </div>
        <Wave />
        {/* <Products Range="Mutton" products={products.mutton} /> */}
      </div>
    </Section>
  );
}

export default React.memo(BestSeller);
