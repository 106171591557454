// extracted by mini-css-extract-plugin
export const container = "about-module--container--1P4xJ";
export const outerborder = "about-module--outerborder--TiFw3";
export const middleBorder = "about-module--middleBorder--kWN_2";
export const innerBorder = "about-module--innerBorder--3yMdu";
export const bottomLeft = "about-module--bottomLeft--3wCnT";
export const bottomRight = "about-module--bottomRight--9atJ-";
export const topLeft = "about-module--topLeft--2jq3F";
export const topRight = "about-module--topRight--12yK1";
export const centerTop = "about-module--centerTop--3Iu1D";
export const centerBottom = "about-module--centerBottom--gJl0O";
export const info = "about-module--info--wE5jT";
export const cardTitle = "about-module--cardTitle--1M1yI";
export const stackTop = "about-module--stackTop--3DIIH";
export const stackBottom = "about-module--stackBottom--3XN7I";
export const InfoBackgroundHeight = "about-module--InfoBackgroundHeight--3Y9jW";