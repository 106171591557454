import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import * as styles from "../../../styles/sections/about/about.module.css";
import Section from "../Section";
import Mandala from "./Mandala";
function About() {
  const data = useStaticQuery(graphql`
    {
      strapiHomepage {
        AboutInfo
        AboutTitle
        AboutImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  `);
  return (
    <Section>
      <div className={styles.container}>
        <div className={styles.stackTop}>
          <div className={styles.outerborder}>
            <div className={styles.middleBorder}>
              <div className={styles.innerBorder}>
                <img
                  className={styles.topLeft}
                  src="/images/about/corner-decoration.jpeg"
                  alt=""
                />
                <img
                  className={styles.topRight}
                  src="/images/about/corner-decoration.jpeg"
                  alt=""
                />
                <img
                  className={styles.bottomLeft}
                  src="/images/about/corner-decoration.jpeg"
                  alt=""
                />
                <img
                  className={styles.bottomRight}
                  src="/images/about/corner-decoration.jpeg"
                  alt=""
                />
                <img
                  className={styles.centerTop}
                  src="/images/about/center-decoration.png"
                  alt=""
                />
                <img
                  className={styles.centerBottom}
                  src="/images/about/center-decoration.png"
                  alt=""
                />
                <div className={styles.info}>
                  <Mandala />
                  <h5 className={styles.cardTitle}>
                    {data.strapiHomepage.AboutTitle}
                  </h5>
                  {/* <h6 className={styles.cardSubtitle}></h6> */}
                  <p style={{ textAlign: "justify" }}>
                    {data.strapiHomepage.AboutInfo}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.stackBottom}>
          <GatsbyImage
            className={`${styles.InfoBackgroundHeight}`}
            image={
              data.strapiHomepage.AboutImage.childImageSharp
                .gatsbyImageData
            }
            alt="About Section Background Image of food"
          />
        </div>
      </div>
    </Section>
  );
}

export default About;
